.wr_colleges {
  margin: auto;
  overflow-y: hidden;
  margin-top: 70px;
}

.icnCls {
  color: var(--secondary-color);
  height: 20px;
  width: 20px;
}

.backgroundImg {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100vh;
  position: fixed;
  opacity: 0.8;
  z-index: -1;
}

.InfoCard {
  border-radius: 10px;
  width: 300px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.delButton:hover {
  cursor: pointer;
  color: red;
}

.instituteCardHeading {
  font-size: 1.1rem;
  color: var(--primary-color);
  margin-right: 30px;
}

.instituteLogoImg {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.viewMoreBtn {
  width: 150px;
  margin: 10px;
  height: 35px;
  background-color: var(--secondary-color);
}
.cardInfo {
  font-size: 0.96rem;
}
.searchBarSection {
  z-index: 9;
  width: 100%;
  position: fixed;
  background-color: white;
}
.hide {
  display: none;
}
.searchBar {
  background-color: white;
  outline: none;
  width: 300px;
  /* border: 1px solid var(--primary-color); */
  margin-left: calc((100% - 300px) / 2);
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(235, 232, 232);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.searchButton {
  width: 65px;
  transform: translateX(-65px);
  background-color: var(--primary-color);
  margin-top: 10px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}
.paginationComponent {
  width: 300px;
}
.pagination {
  /* padding-bottom: 50px; */
  background-color: aliceblue;
  border-radius: 20px;
  padding: 10px !important;
  /* margin: 0px auto; */
  font-size: 11px;
}

.pagination a {
  color: black !important;
  font-weight: 500;
  cursor: pointer;
  margin: 1px;
  padding: 5px 8px;
  background-color: none;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.pagination a:hover {
  background-color: rgb(121, 121, 121);
}
.active a {
  border: 1px solid white;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.test {
  position: fixed;
}
.NoInstFound {
  width: 150px;
  height: 150px;
}

@media screen and (min-width: 425px) {
  .InfoCard,
  .searchBar,
  .paginationComponent {
    width: 400px;
  }
  .searchBar {
    margin-left: calc((100vw - 400px) / 2);
  }
}

@media screen and (min-width: 768px) {
  .instituteCardHeading {
    font-size: 1.22rem;
  }
  .instituteLogoImg {
    width: 150px !important;
    height: 150px !important;
  }
  .InfoCard,
  .searchBar,
  .paginationComponent {
    width: 600px;
  }
  .pagination a {
    padding: 5px 10px;
  }
  .searchBar {
    margin-left: calc((100vw - 600px) / 2);
  }

  .NoInstFound {
    width: 250px;
    height: 250px;
  }
  .pagination {
    font-size: 15px;
    /* padding-bottom: 0; */
    margin: 20px 0;
  }
}

@media screen and (min-width: 992px) {
  .hide {
    display: none;
  }
  .searchBarSection {
    position: relative;
    background-color: transparent;
  }
  .searchBarSection .show {
    display: none;
  }
  .searchBar {
    background-color: white;
  }
  .searchBar,
  .searchButton {
    margin-top: 20px;
  }

  .InfoCard,
  .searchBar,
  .paginationComponent,
  .notFoundSection {
    margin-left: calc((100% - 360px) / 2);
  }

  .wr_colleges {
    width: 100%;
  }

  .test {
    position: absolute;
  }

  .NoInstFound {
    width: 400px;
    height: 400px;
  }
}

@media screen and (min-width: 1200px) {
  .InfoCard,
  .searchBar,
  .paginationComponent {
    width: 700px;
  }
}
