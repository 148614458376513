/* CSS for Form */
.instituteCardForm {
  padding: 10px;
}
.instituteCardForm > fieldset {
  margin: 10px;
}
.instituteCardForm input {
  width: 100%;
}
.submitBtn {
  width: 100px;
  color: white;
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
}
/* CSS for component */

.wr_cards {
  max-width: 1200px;
}

.singleCard,
.institutionHImage {
  width: 130px;
  height: 130px;
}
.singleCard {
  overflow: hidden;
  text-overflow: ellipsis;
}
.singleCard:hover {
  cursor: pointer;
}
.institutionHImage {
  object-fit: cover;
}
.instituteTitle {
  color: aliceblue;
  z-index: 6;
  bottom: 0;
  transition: bottom 0.3s ease;
}
.singleCard:hover .instituteTitle {
  bottom: 40%;
}

.singleCard::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.732);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.singleCard:hover::after {
  opacity: 1;
}

.delButton {
  z-index: 4;
}

@media screen and (min-width: 425px) {
  .singleCard,
  .institutionHImage {
    width: 180px;
    height: 180px;
  }
}

@media screen and (min-width: 768px) {
  .singleCard,
  .institutionHImage,
  .addInstituteButton {
    width: 300px;
    height: 300px;
  }
  .addInstituteButton {
    border: 1px solid grey;
  }
  .test3 {
    z-index: 2;
  }
}

@media screen and (min-width: 1200px) {
  .singleCard,
  .institutionHImage,
  .addInstituteButton {
    width: 350px;
    height: 350px;
  }
}
