.wr_featuredCollege {
  background-color: var(--primary-color);
}
.collegeCard {
  width: 90%;
  margin: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collegeCard img {
  object-fit: cover;
  width: 130px;
  height: 130px;
}
.collegeCard p {
  font-size: 1.2rem;
  color: var(--secondary-color);
}

/*css for featured colleges*/
.featuredCollegesForm {
  padding: 10px;
}
.featuredCollegesForm > fieldset {
  margin: 10px;
}
.featuredCollegesForm input {
  padding: 10px;
  width: 100%;
}

@media screen and (min-width: 425px) {
  .collegeCard {
    width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .collegeCard {
    width: 300px;
  }
}
