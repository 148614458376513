.coursesPage {
  min-height: 500px;
}
.wr_courseCard {
  gap: 10px;
}
.courseCard {
  font-weight: 200;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  width: 90%;
  font-size: 1.5rem;
  color: rgb(6, 40, 61);
}
.courseCard:hover {
  cursor: pointer;
  color: var(--primary-color);
}
.courseCard:last-child {
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .courseCard {
    width: 300px;
  }
}
@media screen and (min-width: 1200px) {
  .wr_courseCard {
    max-width: 1300px;
  }
  .courseCard {
    width: 360px;
  }
}
