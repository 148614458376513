.addInstituteForm {
  padding: 10px;
}
.formHeading {
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}
.addInstituteForm > fieldset {
  margin: 10px !important;
}
.formLabel {
  font-weight: 600;
}
.customRadioLabel {
  background-color: white;
  color: var(--primary-color);
  border: 0.2px solid var(--primary-color);
  padding: 5px 30px;
  border-radius: 40px;
}
.customRadio:checked + .customRadioLabel {
  background-color: var(--primary-color);
  color: white;
  border: 0.2px solid var(--primary-color);
}
.fileBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.fileBox input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.stateRow > fieldset {
  width: 50%;
}

.submitBtn {
  width: 100px;
  color: white;
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
}
