@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");

:root {
  --primary-font-size: 16px;
  --primary-color: rgb(27, 26, 85);
  --secondary-color: rgb(83, 92, 145);
  --heading-font-family: "Libre Baskerville", serif;
  --heading-font-style: italic;
  --heading-font-weight: 700;
}

html {
  font-size: var(--primary-font-size);
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(218, 217, 217, 0.582) !important;
}
h2 {
  text-align: center;
  font-family: var(--heading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Set styles for input type=number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-swal-title {
  color: #2c3e50;
  font-size: 24px;
  font-weight: bold;
  font-style: normal !important;
}

.custom-swal-text {
  color: #34495e;
  font-size: 18px;
}

.custom-swal-button {
  background-color: #27ae60 !important;
  color: white !important;
  border: none;
  border-radius: 5px;
}

.custom-swal-cancel-button {
  background-color: #c0392b !important;
  color: white !important;
  border: none;
  border-radius: 5px;
}

/*---------------------- Scroll Bar CSS ------------------------------------------*/
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
