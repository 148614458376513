.navbar {
  height: 60px;
  /* padding: 20px; */
  background-color: white;
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.logo {
  font-size: 2rem;
  color: var(--primary-color);
}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .offCanvasNavbar {
    background-image: url("../Assets/mobileNavImg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin: 10px;
    font-weight: 550;
  }
  .nav-links a:hover,
  .addUniversityBtn:hover {
    background-color: var(--secondary-color);
  }
  .mobileMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nav-links .active {
    color: white;
    font-weight: 500;
    background-color: var(--primary-color);
  }

  .addUniversityBtn {
    color: black;
    text-decoration: none;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    margin: 15px;
    border: none;
  }
}

@media screen and (min-width: 992px) {
  .navbar {
    height: 90px;
    /* padding: 20px; */
    background-color: white;
    position: fixed !important;
    top: 0;
    width: 100vw;
    z-index: 1000;
  }

  .nav-links a {
    text-decoration: none;
    color: black;
    border-radius: 4px;
    margin: 10px;
    font-weight: 550;
  }

  .nav-links a:hover,
  .addUniversityBtn:hover {
    background-color: var(--secondary-color);
  }

  .nav-links .active {
    color: white;
    font-weight: 500;
    background-color: var(--primary-color);
  }

  .addUniversityBtn {
    color: black;
    text-decoration: none;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    margin: 15px;
    border: none;
  }
}
