.IbackButton {
  z-index: 1;
  background-color: white;
  color: black;
  padding: 2px;
  font-size: 17px;
}

.wrCollegeInfoPage {
  max-width: 1200px;
  top: 120px;
}
.custom-modal .modal-dialog {
  width: 80%;
  max-width: 800px;
}

.instituteInfoBanner {
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.instituteInfoBanner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
}

.headingBlock {
  top: 100px;
  left: 0;
  width: 100%;
  justify-content: center;
}

.instituteLogo {
  width: 150px;
  padding: 10px;
  height: 150px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  object-fit: contain;
  z-index: 2;
}
.instituteHeading {
  z-index: 1;
}
.instituteHeadingw {
  text-align: center;
  max-width: 300px;
  text-wrap: wrap;
  text-overflow: ellipsis;
}
.contactCard {
  min-width: 250px;
  word-wrap: break-word;
}
.infoSubHeading {
  font-family: var(--heading-font-family);
  color: var(--primary-color);
}
.InfosubCard {
  border-radius: 5px;
  min-width: 280px;
  max-width: 900px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: fit-content;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.InfosubCard .editBtn {
  background-color: transparent;
}
.editIcns {
  width: 25px;
  height: 25px;
}
.iconCls {
  color: var(--secondary-color);
}
.cancelBtn {
  background-color: transparent;
}
.courseName {
  font-size: 1rem;
  margin-right: 15px;
  font-weight: 500;
}
.jodit-workplace {
  min-height: 400px !important;
  max-height: 550px;
  overflow-y: scroll;
}
.courseForm {
  padding: 10px;
}
.courseFormHeading {
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}
.courseForm > fieldset {
  margin: 10px;
}
.courseForm .label {
  font-weight: 500;
}
/* input,
select,
textarea {
  border: none;

  border-bottom: 1px solid grey;
} */
.confirmNameBtn:hover {
  background-color: green;
  border-radius: 50%;
}
.CancelBtn:hover {
  background-color: rgb(204, 48, 48);
  border-radius: 50%;
}

.delHover {
  margin: 0;
}
.delHover:hover {
  cursor: pointer;
  color: red;
}
.editName,
.copytoClipboard {
  cursor: pointer;
}
.copytoClipboard:active {
  color: blue;
  font-size: 1.1rem;
}
.departmentName {
  padding-left: 10px;
  color: var(--secondary-color);
}
.courseDuration p {
  font-size: 0.9rem;
  color: grey;
}
.addEligibilityBtn {
  background-color: transparent;
  right: 30px;
}
.eligibilityInfo {
  resize: none;
  height: 200px;
}
.aboutCourse {
  height: 250px;
  resize: none;
}
.about {
  color: grey;
  font-size: 0.95rem;
}

.coursecard {
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 0px 0px;
  padding: 10px;
  padding-left: 15px;
  cursor: pointer;
}
.react-tabs__tab {
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}
.bannerButton {
  display: flex;
}
.infoDetails {
  cursor: pointer;
}

.infoDetails:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 0;
  border-radius: 10px;
  width: 200px;
  background-color: #b1b1b1;
  color: #f5f5f5;
  padding: 10px;
  font-size: 14px;
  z-index: 1;
}
.aboutCourseModal p {
  margin-bottom: 0;
}

.courseModalTitle {
  font-size: 18px !important;
  font-family: var(--heading-font-family);
  color: var(--primary-color) !important;
}
@media screen and (min-width: 768px) {
  .headingBlock {
    padding-left: 5rem;
    justify-content: start;
  }
  .headingEditBlock {
    top: -20px;
  }
  .instituteLogo {
    width: 170px;
    height: 170px;
  }
  .InfosubCard {
    margin-top: 0;
    margin-inline: 2rem;
    margin-bottom: 20px;
  }
  .saveBtn {
    z-index: 1;
    cursor: pointer;
    font-size: 2rem;
  }

  .instituteHeading {
    margin-left: 10px;
    font-size: 1.5rem;
    top: -30px;
    color: white;
  }
  .instituteHeadingw {
    max-width: 100%;
    text-align: left;
  }
  .infoSubHeading {
    font-size: 1.3rem;
  }
  .react-tabs__tab {
    font-size: 1.3rem;
  }
  .editImg {
    font-size: 1.3rem;
    color: var(--primary-color);
    z-index: 2;
    transform: translateX(150px);
    cursor: pointer;
  }
  .courseName {
    font-size: 1.2rem;
  }
  .courseText {
    font-size: 15px;
    font-weight: 500;
    color: rgb(126, 126, 126);
  }
}

@media screen and (min-width: 992px) {
  .InfosubCard {
    width: 700px;
  }
  .instituteHeading {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .headingBlock {
    padding-left: 10rem;
  }
}

@media screen and (min-width: 1600px) {
  .headingBlock {
    padding-left: 21rem;
  }
}
