@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap");

.pageNotFoundWrap {
  height: 90vh;
}

.pageNotFoundWrap p {
  font-family: "Domine", serif;
  font-weight: 500;
  font-style: italic;
}
.pageNotFoundWrap img {
  width: 200px;
  height: 200px;
}

@media screen and (min-width: 768px) {
  .pageNotFoundWrap img {
    width: 300px;
    height: 300px;
  }
}

@media screen and (min-width: 992px) {
  .pageNotFoundWrap img {
    width: 400px;
    height: 400px;
  }
}
