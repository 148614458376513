.login_page_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_form {
  padding: 20px;
  color: white;
  width: 280px;
  height: 250px;
  background-color: #526d81;
  border-radius: 0 0 20px 20px;
}
.login_image {
  width: 280px;
  border-radius: 0 0 20px 20px;
  border-radius: 20px 20px 0 0;
}
.login_form Button {
  margin: 10px auto;
  background-color: #ee8334;
  color: white;
  font-weight: 500;
  border: none;
}
@media (min-width: 768px) {
  .login_page {
    padding: 100px;
    display: flex;
    justify-content: center;
  }
  .login_page_card {
    flex-direction: row;
    align-items: center;
    width: 60vw;
    max-width: 600px;
    /* height: 350px; */
    justify-content: center;
    background-color: #526d81;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
  .login_form {
    background-color: transparent;
    width: 45%;
  }
  .login_page img {
    width: 40%;
    height: auto;
  }
}
@media screen and (min-width: 1200px) {
  .login_page_card {
    max-width: 800px;
    height: 270px;
  }
}
@media screen and (min-width: 1440px) {
  .login_page_card {
    height: 500px;
  }
  .login_form {
    width: 50%;
  }
  .login_page img {
    width: 50%;
  }
}
