.bgImage {
  height: 400px;
  background-image: url("../../../Assets/homePageBackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.carouselImg {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.carouselImgWrapper {
  height: 400px;
}

.addBtn,
.editBtn,
.delBtn {
  z-index: 100;
  padding: 10px;
  margin: 10px;
  background-color: white;
  color: var(--primary-color);
  border: none;
}

.edtdelBtns {
  z-index: 100;
  bottom: 65px;
}

.addBtn:hover {
  cursor: pointer;
}

.carouselForm {
  padding: 10px;
}
.carouselForm input {
  width: 100%;
}
.carouselForm > fieldset {
  margin: 10px;
}
.submitBtn {
  width: 100px;
  color: white;
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
}
