/*---------------------------------mobile filter-----------------------------*/

.offCanvasFilter {
  width: 300px;
}
.vertical-tabs-container {
  display: flex;
  font-size: 14px;
}
.tab-content {
  width: calc(100% - 150px);
  max-height: 83vh;
  overflow-y: auto;
}

.vertical-tabs {
  width: 150px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: rgb(231, 231, 231);
}

.tab {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: var(--secondary-color);
  color: white;
}

/*-----------------------------------------desktop filters------------------------------------*/

.accordion-button:focus {
  box-shadow: none !important;
}

.filterSection {
  position: fixed;
  z-index: 10;
  width: 300px;
  height: 100vh;
}
.clearAll {
  color: rgb(163, 163, 163);
  cursor: pointer;
}
.clearAll:hover {
  color: rgb(252, 81, 81);
}
.applyFiltersButtonParent {
  width: 300px;
}
.applyFiltersButton {
  width: 150px;
}
.accordion-element {
  height: 100vh;
  margin-bottom: 110px;
  overflow-y: scroll;
}
.accordion-scrollable {
  /* max-height: 400px; */
}
.filterHeading {
  font-size: 1.2rem;
  height: 40px;
}
.checkBoxFields {
  display: flex;
  align-items: center;
}

.filterButton {
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
}

.filterButtons button,
.applyFiltersButton {
  background-color: var(--primary-color);
  border: 0;
  border-left: 1px solid white;
}
.filterButtons button:first-child,
.applyFiltersButtonParent button:first-child {
  border-left: 0;
}
.moveToTopButton {
  background-color: rgb(255, 255, 255, 0.8);
}
